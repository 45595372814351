export default {
  address: "宜蘭縣蘇澳鎮海山西路/馬賽路交岔口（蠟筆城堡旁）",
  googleSrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d906.8003369365716!2d121.82645282926926!3d24.616744318228204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjTCsDM3JzAwLjMiTiAxMjHCsDQ5JzM3LjIiRQ!5e0!3m2!1szh-TW!2stw!4v1628334070649!5m2!1szh-TW!2stw",
  googleLink: "https://goo.gl/maps/tgKASisg7aDH9N3T7",
  phone: "03-990-8811",
  fbLink:
    "https://www.facebook.com/The-ARIES-牡羊座-冷泉渡假行館-111516394449508/",
  fbMessage: "https://m.me/111516394449508/",
  caseName: "牡羊座",
  indigatorLength: 10,
  houseInfos: [
    ["投資興建", "青山興業股份有限公司"],
    ["工程營造", "三豐營造有限公司(甲級)"],
    ["建築設計", "九騰建築師事務所-黃婉雯建築師"],
    ["公設規劃", "伏見設計事業有限公司-鍾晴設計總監"],
    ["外觀設計", "九騰建築師事務所-黃婉雯建築師"],
    ["景觀規劃", "老圃造園工程股份有限公司-黃盟峰設計師"],
    ["企劃設計", "城豐國際有限公司"],
  ],

  gtmCode: ["WCNSDTS"], // 可放置多個
  recaptcha_site_key_v2: "6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz",
  recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
  recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
  order: {
    title: "預約賞屋",
    subTitle: "若想了解建案資訊，歡迎填寫表單或來電洽詢，<br>將由專人為您服務，謝謝！"
  }
};
