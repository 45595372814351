import { render, staticRenderFns } from "./section4.vue?vue&type=template&id=29806d8e&scoped=true&"
import script from "./section4.vue?vue&type=script&lang=js&"
export * from "./section4.vue?vue&type=script&lang=js&"
import style1 from "./section4.vue?vue&type=style&index=1&id=29806d8e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29806d8e",
  null
  
)

export default component.exports